import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Website Customization",
      desc: "I specialize in tailoring your online presence to suit your unique vision and business needs. I'm dedicated to transforming your website into a powerful tool that captivates your audience and drives results.",
      icon: "fas fa-palette",
    },
    {
      name: "Database Architecture",
      desc: "Elevate your online presence with a comprehensive service that seamlessly combines web design and robust database architecture. Recognizing that a well-structured database is the backbone of a powerful and consistent website.",
      icon: "fas fa-database",
    },
    {
      name: "Technology consistency",
      desc: "It's all about ensuring uniformity, reliability, and stability in technology usage and implementation. My approach guarantees seamless technology solutions that adhere to industry standards, delivering a consistent user experience.",
      icon: "fas fa-cogs",
    },
    {
      name: "Techology consultancy",
      desc: "I excel in leveraging the latest innovations, customizing software solutions to your requirements, and optimizing existing technologies. I'm your partner in staying at the forefront of tech advancements and ensuring that your digital journey is both efficient and innovative.",
      icon: "fas fa-laptop-code",
    },
    {
      name: "Business Analysis",
      desc: "I also offer comprehensive business analysis support as part of my services, encompassing detailed wireframe creation and the utilization of Rapid Application Development (RAD) methodologies. My goal is to ensure that technology solutions align seamlessly with your business objectives.",
      icon: "fas fa-chart-area",
    },
    {
      name: "Big Data Analytics",
      desc: "I place a strong emphasis on data consistency, which is achieved through the well-designed application of skillful database modeling techniques. This meticulous approach enables the extraction of valuable insights from your data, seamlessly facilitating in-depth Data Analytics.",
      icon: "fas fa-chart-bar",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
